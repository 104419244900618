<script>
import { computed, ref, watch } from 'vue'
import { roundFigure } from '/~/utils/format/numeric'
import BaseCurrencyInput from '/~/components/base/currency-input/currency-input.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import { useLocalization } from '/~/composables/localization'
import { useProvider } from '/~/composables/provider'

export default {
  name: 'payments-split-form-bank-account',
  components: {
    BaseIcon,
    BaseCurrencyInput,
  },
  props: {
    value: {
      type: Number,
      default: 0,
    },
    bankAccount: {
      type: Object,
      required: true,
    },
    amountToPay: {
      type: Number,
      default: 0,
    },
  },
  emits: ['focus'],
  setup(props, { emit }) {
    const currencyInputRef = ref(null)
    const calculatedAmount = computed({
      get: () => props.value,
      set: (value) =>
        emit('input', Math.min(maxAmount.value, roundFigure(value))),
    })
    const maxAmount = computed(() =>
      roundFigure(props.amountToPay + calculatedAmount.value)
    )
    const { isBillPaymentsTemplate } = useProvider()
    const { translate } = useLocalization()

    watch(calculatedAmount, (calculatedAmount) => {
      if (calculatedAmount >= maxAmount.value) {
        currencyInputRef.value?.blur()
      }
    })

    return {
      currencyInputRef,
      maxAmount,
      calculatedAmount,
      isBillPaymentsTemplate,
      translate,
    }
  },
}
</script>

<template>
  <div class="mt-10 w-full rounded-lg bg-gray-50 p-5">
    <div class="justify-between sm:flex">
      <div class="flex">
        <div
          class="mr-[15px] flex h-12 w-12 shrink-0 items-center justify-center rounded-full text-white"
          :class="{
            'bg-emerald-700': isBillPaymentsTemplate,
            'bg-primary': !isBillPaymentsTemplate,
          }"
        >
          <base-icon svg="v2/custom/bank" size="md" />
        </div>
        <div>
          <div class="-mb-[5px] font-bold">
            {{ bankAccount.accountName }}
          </div>
          <div class="-mt-[5px] text-sm text-eonx-neutral-600">
            {{
              translate('bankAccount.details', {
                acc: `*** *** *${bankAccount.accountNumber.slice(-2)}`,
                bsb: bankAccount.bsb,
              })
            }}
          </div>
        </div>
      </div>
      <div class="mt-2.5 flex pt-[5px] text-eonx-neutral-600 sm:mt-0">
        <div
          class="flex h-[35px] w-[120px] items-center rounded-md border bg-white pl-2.5 pr-[5px] focus-within:border-primary"
        >
          <base-currency-input
            ref="currencyInputRef"
            v-model="calculatedAmount"
            :value-range="{ max: maxAmount }"
            @focus="$emit('focus')"
          />
        </div>
      </div>
    </div>
  </div>
</template>
