<script>
import { computed } from 'vue'
import { formatDollar } from '/~/utils/format/money'
import { roundFigure } from '/~/utils/format/numeric'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseRange from '/~/components/base/range/base-range.vue'
import { useCms } from '/~/composables/cms/use-cms'
import { useEwallet } from '/~/composables/ewallet'

export default {
  name: 'payments-split-form-ewallet',
  components: {
    BaseRange,
    BaseIcon,
  },
  props: {
    value: {
      type: Number,
      default: 0,
    },
    amountToPay: {
      type: Number,
      default: 0,
    },
  },
  setup(props, { emit }) {
    const { ewalletBalance } = useEwallet()
    const { ewalletLabels } = useCms()

    const calculatedAmount = computed({
      get: () => props.value,
      set: (value) =>
        emit('input', Math.min(maxAmount.value, roundFigure(value))),
    })
    const maxAmount = computed(() =>
      roundFigure(props.amountToPay + calculatedAmount.value)
    )

    return {
      formatDollar,
      maxAmount,
      calculatedAmount,
      ewalletBalance,
      ewalletLabels,
    }
  },
  computed: {
    balanceRemaining() {
      return this.ewalletBalance - this.calculatedAmount
    },
    max() {
      return Math.min(this.ewalletBalance, this.maxAmount)
    },
  },
}
</script>

<template>
  <div class="mt-10 w-full rounded-lg bg-gray-50 p-5">
    <div class="justify-between sm:flex">
      <div class="flex">
        <base-icon class="mr-[15px] h-[35px]" svg="plain/ewallet" size="lg" />
        <div>
          <div class="-mb-[5px] font-bold">
            Pay with {{ ewalletLabels.ewalletCash }}
          </div>
          <div class="-mt-[5px] text-sm text-eonx-neutral-600">
            {{ ewalletLabels.ewalletCashBalance }}
            {{ formatDollar(balanceRemaining) }}
          </div>
        </div>
      </div>
      <div class="text-right text-eonx-neutral-600">
        <div class="font-bold">
          {{ formatDollar(calculatedAmount) }}
        </div>
        <div class="text-sm">Value</div>
      </div>
    </div>
    <base-range
      v-model="calculatedAmount"
      class="mt-6 w-full"
      :min="0"
      :dot-size="32"
      :max="max"
      :interval="0.01"
      :tooltip="'none'"
      placement="top"
      formatter="{value}"
    />
  </div>
</template>
